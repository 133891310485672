<template>
  <main class="main">
    <section class="welcome welcome__policy">
      <div class="container">
        <div class="welcome__inner">
          <h1 class="welcome__title welcome__policy__title">
            Политика конфиденциальности
          </h1>
        </div>
      </div>
    </section>

    <section class="section container" v-if="policy && policy.value">
      <div class="policy__container form__container" v-html="policy.value">
      </div>
    </section>
  </main>
</template>

<script>
export default {
  async asyncData ({ store }) {
    await store.dispatch('GET_POLICY_PAGE')
  },
  name: 'policy',
  computed: {
    policy () {
      return this.$store.state.policy_page?.page_texts?.policy
    },
    nav () {
      return this.$store.state.navigators.find(item => item.link === this.$route.path)
    }
  },
  metaInfo () {
    return this.$seo(
      'common',
      this.nav.meta_title,
      this.nav.meta_keywords,
      this.nav.meta_description,
      'Mavadda',
      '',
      'Mavadda'
    )
  },
  jsonld () {
    return {
      '@context': 'https://schema.org',
      '@type': 'Organization'
    }
  }
}
</script>
